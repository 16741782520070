@import "./variables";

.loader {
  gap: 1rem;
  flex-direction: column;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  background: #363335;
  transition: 0.4s all ease;

  img {
    width: 100px;
    margin: auto;
    transition: 0.4s all ease;
  }

  &.hide {
    width: 0%;

    img {
      opacity: 0;
    }
  }
}
